// 设置 rem 函数
function setRem() {
  var pageWidth = window.innerWidth;
  //为了ie也能拿到可视窗口宽度
  if (typeof pageWidth != "number") {
    //标准模式
    if (document.compatMode == "CSS1Compat") {
      pageWidth = document.documentElement.clientWidth;
      //怪异模式
    } else {
      pageWidth = document.body.clientWidth;
    }
  }
  var baseSize, scale;
  if (pageWidth <= 750) {
    baseSize = 12;
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
    scale = document.documentElement.clientWidth / pageWidth;
  } else if (pageWidth > 750 && pageWidth <= 1200) {
    baseSize = 14;
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
    scale = document.documentElement.clientWidth / pageWidth;
  } else {
    baseSize = 16;
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
    scale = document.documentElement.clientWidth / 1920;
  }
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 2) + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem();
};

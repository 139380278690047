import { createStore } from "vuex";
import system from "./system";
import constants from "./constants";

export default createStore({
  modules: {
    system,
    constants
  },
});

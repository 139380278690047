<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in leftItems">
        <template v-if="item.subs.length >0">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                v-if="subItem.subs"
                :index="subItem.index"
                :key="subItem.index"
              >
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <!-- <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template> -->
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "iconfont tlicon-auth",
          index: "account",
          title: "账户权限",
          subs: [
            { index: "user", title: "用户管理" },
            { index: "role", title: "角色管理" },
          ],
        },
        {
          icon: "iconfont tlicon-kehu-copy",
          index: "partner",
          title: "客商资料",
          subs: [
            { index: "customer", title: "客户管理" },
            { index: "contract", title: "合同管理" },
          ],
        },
        {
          icon: "iconfont tlicon-dizhibu",
          index: "addressbook",
          title: "地址库",
          subs: [
            // { index: "country", title: "国家" },
            { index: "warehouse", title: "仓库" },
          ],
        },
        {
          icon: "iconfont tlicon-shuju",
          index: "basedata",
          title: "其他主数据",
          subs: [
            { index: "iata", title: "港口" },
            { index: "rate", title: "费率" },
            { index: "chargeGroup", title: "费用组" },
            { index: "charge", title: "费用" },
          ],
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.system.collapse;
    },
    leftItems() {
      let items = [
        {
          icon: "iconfont tlicon-auth",
          index: "account",
          title: "账户权限",
          subs: [],
        },
        {
          icon: "iconfont tlicon-kehu-copy",
          index: "partner",
          title: "客商资料",
          subs: [],
        },
        {
          icon: "iconfont tlicon-dizhibu",
          index: "addressbook",
          title: "地址库",
          subs: [],
        },
        {
          icon: "iconfont tlicon-shuju",
          index: "basedata",
          title: "其他主数据",
          subs: [],
        },
      ];
      let permissions = JSON.parse(localStorage.getItem("permissions"));

      if (permissions.indexOf("user:view") != -1) {
        items[0].subs.push({ index: "user", title: "用户管理" });
      }
      if (permissions.indexOf("role:view") != -1) {
        items[0].subs.push({ index: "role", title: "角色管理" });
      }
      if (permissions.indexOf("partner:view") != -1) {
        items[1].subs.push({ index: "customer", title: "客户管理" });
      }
      if (permissions.indexOf("contract:view") != -1) {
        items[1].subs.push({ index: "contract", title: "合同管理" });
      }
      if (permissions.indexOf("role:view") != -1) {
        items[2].subs.push({ index: "warehouse", title: "仓库" });
      }
      if (permissions.indexOf("habour:view") != -1) {
        items[3].subs.push({ index: "iata", title: "港口" });
      }
      if (permissions.indexOf("rate:view") != -1) {
        items[3].subs.push({ index: "rate", title: "费率" });
      }
      if (permissions.indexOf("feeGroup:view") != -1) {
        items[3].subs.push({ index: "chargeGroup", title: "费用组" });
      }
      if (permissions.indexOf("fee:view") != -1) {
        items[3].subs.push({ index: "charge", title: "费用" });
      }

      return items;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}

.iconfont {
  margin-right: 5px;
}
</style>

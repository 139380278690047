<template>
  <el-dialog
    :title="$t('user.modifyPwd')"
    v-model="isShow"
    width="400px"
    :before-close="handleClose"
  >
    <el-form
      ref="pwdFrom"
      :inline="true"
      :model="passwordInfo"
      size="mini"
      :rules="passwordRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('user.orgPwd')"
          prop="originpwd"
          label-width="120px"
        >
          <el-input
            v-model.trim="passwordInfo.originpwd"
            type="password"
            :placeholder="$t('system.pleaseInput') + $t('user.orgPwd')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('user.newPwd')"
          prop="newpwd"
          label-width="120px"
        >
          <el-input
            v-model.trim="passwordInfo.newpwd"
            type="password"
            :placeholder="$t('system.pleaseInput') + $t('user.newPwd')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('user.pwdConfirm')"
          prop="confirmpwd"
          label-width="120px"
        >
          <el-input
            v-model.trim="passwordInfo.confirmpwd"
            type="password"
            :placeholder="$t('system.pleaseInput') + $t('user.pwdConfirm')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doModifyPassword" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import mainServer from "@/api/index";
import { validatePassword } from "@/utils/validator.js";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    const validatePasswordConfirm = (rule, value, callback) => {
      value = value && value.trim();
      if (value != this.passwordInfo.newpwd) {
        callback(new Error(this.$t("validate.pwdNotSame")));
      } else {
        callback();
      }
    };

    return {
      passwordInfo: {
        originpwd: null,
        newpwd: null,
        confirmpwd: null,
        userId: JSON.parse(localStorage.getItem("user")).userId,
      },
      passwordRules: {
        originpwd: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.orgPwd"),
            trigger: "blur",
          },
        ],

        newpwd: [
          {
            required: true,
            message: this.$t("system.pleaseInput") + this.$t("user.newPwd"),
            trigger: "blur",
          },
          {
            validator: validatePassword,
            trigger: "blur",
            msg: {
              pwdLength: this.$t("validate.pwdLength"),
              pwdReg: this.$t("validate.pwdReg"),
            },
          },
        ],
        confirmpwd: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("user.password_confirm"),
            trigger: "blur",
          },
          { validator: validatePasswordConfirm, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    isShow() {
      return this.isShowDialog;
    },
  },
  methods: {
    doModifyPassword() {
      this.$refs["pwdFrom"].validate((valid) => {
        if (valid) {
          mainServer.changePwd(this.passwordInfo).then(() => {
            localStorage.removeItem("ms_username");
            localStorage.removeItem("access_token");
            this.$router.push("/login");
          });
        }
      });
    },
    handleClose() {
      this.$emit("onDialogClose");
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>

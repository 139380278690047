import ElementPlus from 'element-plus'
import {
  createI18n
} from 'vue-i18n'
import 'element-plus/lib/theme-chalk/index.css'
import localeZH from 'element-plus/lib/locale/lang/zh-cn'
import localeEN from 'element-plus/lib/locale/lang/en'

let en = require("@/assets/languages/en.js");
let cn = require("@/assets/languages/zh-cn.js")

const messages = {
  [localeEN.name]: Object.assign({}, {
    el: localeEN.el,
    ...en,
  }),
  [localeZH.name]: Object.assign({}, { 
    el: localeZH.el,
    ...cn,
  }),
}

const i18n = createI18n({
  locale: localStorage.getItem('locale') || localeZH.name,
  fallbackLocale: localeEN.name,
  messages,
})


export default (app) => {
  app.use(ElementPlus, {
    i18n: i18n.global.t, //!
  })
  app.use(i18n)
}
import mainServer from '@/api/index'
const state = {
    saleCompanys:[],
    currencyList:[
      "CNY","EUR","USD","HKD","JPY"
    ]
  };
  
  const mutations = {
   
    setSaleCompanys(state, data) {
      state.saleCompanys = data;
    },
    
   
  };
  
  const actions = {
    loadSaleCompanys({
        commit
    }) {
        mainServer.getSaleCompany({}).then((res) => {
            commit('setSaleCompanys', res.data)
        });
    },
  };
  
  export default {
    state,
    mutations,
    actions,
  };
  
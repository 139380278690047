import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element' 
import NProgress from 'nprogress'
import "nprogress/nprogress.css"
import onlyNumber from '@/plugins/el-input/onlyNumber';
import '@/utils/setRem'


NProgress.inc(0.2)
NProgress.configure({
    easing: 'ease',
    speed: 500,
    showSpinner: false
})


const app = createApp(App)

app.directive('onlyNumber',onlyNumber)

installElementPlus(app)
app
    .use(store)
    .use(router)
    .mount('#app')
import axios from "axios";
import {
  ElMessage
} from "element-plus";
import JSONbig from 'json-bigint'

// console.log(process.env.NODE_ENV);

// let headers;
// if (localStorage.getItem("access_token")) {
//   headers = {
//     "Content-Type": "application/json; charset=UTF-8",
//     Authorization: "Bearer " + localStorage.getItem("access_token"),
//   };
// } else {
//   headers = {
//     "Content-Type": "application/json; charset=UTF-8",
//     Authorization: null,
//   };
// }

const service = axios.create({
  baseURL: '/adminapi',
  // headers: headers,
  timeout: 180000,
  transformResponse: [
    data => {
      try {
        return JSONbig.parse(data);
      } catch (err) {
        return data;
      }
    },
  ],
});

service.interceptors.request.use(
  (config) => {
    
    if (localStorage.getItem("access_token")) {
      config.headers = {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      };
    } else {
      config.headers = {
        "Content-Type": "application/json; charset=UTF-8",
      };
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data) {
      if (response.data.code == 200) {
        return response.data;
      } else if (response.data.code == -1) {
        ElMessage.warning({
          message: response.data.message,
          type: "warning",
        });
        return Promise.reject(response.data);
      } else {
        return Promise.reject(response.data);
      }
    } else {
      return Promise.reject("System Error");
    }
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      ElMessage.warning({
        message: "Token expired , relogin!",
        type: "warning",
      });
   
      localStorage.removeItem("ms_username");
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      

      setTimeout(() => {
        window.location.href = "/#/login";
      }, 1000);
    } else {
      ElMessage.error({
        message: error.message,
        type: "warning",
      });
    }
    return Promise.reject("Network Error");
  }
);

export default service;
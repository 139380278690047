<template>
  <div class="header">
    <!-- 折叠按钮 -->

    <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div class="logo">
      <img src="http://www.tolead.com/images/logo-1.png" alt="" />
    </div>

    <!-- <el-menu
        class="sidebar-el-menu"
        :default-active="onRoutes"
       
        background-color="#242f42"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"
        unique-opened
        router
        menu-trigger="hover"
        mode="horizontal"
      >
        <template v-for="item in items">
          <template v-if="item.subs">
            <el-submenu :index="item.index" :key="item.index">
              <template #title>
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>
              <template v-for="subItem in item.subs">
                <el-submenu
                  v-if="subItem.subs"
                  :index="subItem.index"
                  :key="subItem.index"
                >
                  <template #title>{{ subItem.title }}</template>
                  <el-menu-item
                    v-for="(threeItem, i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index"
                    >{{ threeItem.title }}</el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                  v-else
                  :index="subItem.index"
                  :key="subItem.index"
                  >{{ subItem.title }}</el-menu-item
                >
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :index="item.index" :key="item.index">
              <i :class="item.icon"></i>
              <template #title>{{ item.title }}</template>
            </el-menu-item>
          </template>
        </template>
      </el-menu> -->

    <div class="header-right">
      <div class="header-user-con">
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
                    <el-tooltip
                        effect="dark"
                        :content="message?`有${message}条未读消息`:`消息中心`"
                        placement="bottom"
                    >
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>
                </div> -->
        <!-- 用户头像 -->
        <div class="user-avator">
          <img src="../assets/img/img.jpg" />
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="changeLocale">{{
                $t("system.changeLocale")
              }}</el-dropdown-item>
              <el-dropdown-item command="modifyPwd">{{
                $t("user.modifyPwd")
              }}</el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu>
              <el-dropdown-item divided command="loginout">{{
                $t("system.logout")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <modify-password-dialog
      v-if="isShowModifyPwdDialog"
      :isShowDialog="isShowModifyPwdDialog"
      @onDialogClose="closeModifyPwdDialog"
    />
  </div>
</template>
<script>
import {mapActions} from "vuex"
import mainServer from "@/api/index";
import ModifyPasswordDialog from "./ModifyPassword.vue";
export default {
  components: {
    ModifyPasswordDialog,
  },
  data() {
    return {
      fullscreen: false,
      isShowModifyPwdDialog: false,
      name: "linxin",
      message: 2,

      items: [
        {
          icon: "el-icon-lx-home",
          index: "organization",
          title: "组织架构",
        },
        {
          icon: "el-icon-lx-cascades",
          index: "account",
          title: "账户权限",
          subs: [
            { index: "user", title: "用户管理" },
            { index: "role", title: "角色管理" },
          ],
        },
        {
          icon: "el-icon-lx-copy",
          index: "customer",
          title: "客商资料",
        },
        {
          icon: "el-icon-lx-calendar",
          index: "addressbook",
          title: "地址库",
          subs: [
            { index: "country", title: "国家" },
            { index: "warehouse", title: "仓库" },
          ],
        },
        {
          icon: "el-icon-lx-emoji",
          index: "basedata",
          title: "其他主数据",
          subs: [
            { index: "iata", title: "港口" },
            { index: "rate", title: "费率" },
            { index: "chargeGroup", title: "费用组" },
            { index: "charge", title: "费用" },
          ],
        },
        {
          icon: "el-icon-pie-chart",
          index: "auth",
          title: "权限验证",
        },
        {
          icon: "el-icon-lx-warn",
          index: "broadcast",
          title: "广播分发",
        },
      ],
    };
  },
  computed: {
    username() {
      let username = localStorage.getItem("ms_username");
      return username ? username : this.name;
    },
    collapse() {
      return this.$store.state.system.collapse;
    },

    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  methods: {
    ...mapActions(["loadSaleCompanys"]),
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        mainServer
          .logout({})
          .then(() => {
            localStorage.removeItem("ms_username");
            localStorage.removeItem("access_token");
            this.$router.push("/login");
          })
          .catch(() => {
            localStorage.removeItem("ms_username");
            localStorage.removeItem("access_token");
            this.$router.push("/login");
          });
      } else if (command == "changeLocale") {
        this.$i18n.locale == "zh-cn"
          ? (this.$i18n.locale = "en")
          : (this.$i18n.locale = "zh-cn");
        localStorage.setItem("locale", this.$i18n.locale);
      } else if (command == "modifyPwd") {
        this.showModifyPwdDialog();
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.$store.commit("hadndleCollapse", !this.collapse);
    },
    showModifyPwdDialog() {
      this.isShowModifyPwdDialog = true;
    },
    closeModifyPwdDialog() {
      this.isShowModifyPwdDialog = false;
    },
  },
  created(){
    this.loadSaleCompanys();
  },
  mounted() {
    
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
  /* display: flex; */
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  float: left;
  width: 250px;
  height: 70px;
}

.header .logo img {
  width: 140px;
  height: 30px;
  margin-top: 20px;
}

.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}

.sidebar-el-menu {
  flex: 1;
}

:deep(.el-menu.el-menu--horizontal) {
  border-bottom: none;
}

:deep(.el-menu--horizontal > .el-menu-item.is-active) {
  border-bottom: none;
}
:deep(.el-menu--horizontal > .el-submenu .el-submenu__title) {
  border-bottom: none;
  height: 70px;
  line-height: 70px;
}
:deep(.el-menu--horizontal > .el-menu-item) {
  border-bottom: none;
  height: 70px;
  line-height: 70px;
}
:deep(.el-menu--horizontal > .el-submenu.is-active .el-submenu__title) {
  border-bottom: none;
  height: 70px;
  line-height: 70px;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NProgress from "nprogress";
// const routes = [{
//     path: '/',
//     redirect: '/dashboard'
// }, {
//     path: "/",
//     name: "Home",
//     component: Home,
//     children: [{
//         path: "/dashboard",
//         name: "dashboard",
//         meta: {
//             title: '系统首页'
//         },
//         component: () => import(
//             /* webpackChunkName: "dashboard" */
//             "../views/Dashboard.vue")
//     }, {
//         path: "/table",
//         name: "basetable",
//         meta: {
//             title: '表格'
//         },
//         component: () => import(
//
//             "../views/BaseTable.vue")
//     }, {
//         path: "/charts",
//         name: "basecharts",
//         meta: {
//             title: '图表'
//         },
//         component: () => import(
//             /* webpackChunkName: "charts" */
//             "../views/BaseCharts.vue")
//     }, {
//         path: "/form",
//         name: "baseform",
//         meta: {
//             title: '表单'
//         },
//         component: () => import(
//             /* webpackChunkName: "form" */
//             "../views/BaseForm.vue")
//     }, {
//         path: "/tabs",
//         name: "tabs",
//         meta: {
//             title: 'tab标签'
//         },
//         component: () => import(
//             /* webpackChunkName: "tabs" */
//             "../views/Tabs.vue")
//     }, {
//         path: "/donate",
//         name: "donate",
//         meta: {
//             title: '鼓励作者'
//         },
//         component: () => import(
//             /* webpackChunkName: "donate" */
//             "../views/Donate.vue")
//     }, {
//         path: "/permission",
//         name: "permission",
//         meta: {
//             title: '权限管理',
//             permission: true
//         },
//         component: () => import(
//             /* webpackChunkName: "permission" */
//             "../views/Permission.vue")
//     }, {
//         path: "/i18n",
//         name: "i18n",
//         meta: {
//             title: '国际化语言'
//         },
//         component: () => import(
//             /* webpackChunkName: "i18n" */
//             "../views/I18n.vue")
//     }, {
//         path: "/upload",
//         name: "upload",
//         meta: {
//             title: '上传插件'
//         },
//         component: () => import(
//             /* webpackChunkName: "upload" */
//             "../views/Upload.vue")
//     }, {
//         path: "/icon",
//         name: "icon",
//         meta: {
//             title: '自定义图标'
//         },
//         component: () => import(
//             /* webpackChunkName: "icon" */
//             "../views/Icon.vue")
//     }, {
//         path: '/404',
//         name: '404',
//         meta: {
//             title: '找不到页面'
//         },
//         component: () => import(
//             '../views/404.vue')
//     }, {
//         path: '/403',
//         name: '403',
//         meta: {
//             title: '没有权限'
//         },
//         component: () => import( /* webpackChunkName: "403" */
//             '../views/403.vue')
//     }]
// }, {
//     path: "/login",
//     name: "Login",
//     meta: {
//         title: '登录'
//     },
//     component: () => import(
//         /* webpackChunkName: "login" */
//         "../views/Login.vue")
// }];

const routes = [
  {
    path: "/",
    redirect: "/user",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/organization",
        name: "organization",
        meta: {
          title: "组织架构",
          needLogin:true,
          permission: 'organization:view'

        },
        component: () => import("../views/organization/index.vue"),
      },
      {
        path: "/user",
        name: "user",
        meta: {
          title: "用户管理",
          needLogin:true,
          permission: 'user:view'
        },
        component: () => import("../views/account/user.vue"),
      },
      {
        path: "/role",
        name: "role",
        meta: {
          title: "角色管理",
          needLogin:true,
          permission: 'role:view'
        },
        component: () => import("../views/account/role.vue"),
      },
      {
        path: "/customer",
        name: "customer",
        meta: {
          title: "客户管理",
          needLogin:true,
          permission: 'partner:view'
        },
        component: () => import("../views/partner/customer.vue"),
      },
      {
        path: "/contract",
        name: "contract",
        meta: {
          title: "合同管理",
          needLogin:true,
          permission: 'contract:view'
        },
        component: () => import("../views/partner/contract.vue"),
      },
      {
        path: "/country",
        name: "country",
        meta: {
          title: "国家",
          needLogin:true,
          permission: 'country:view'
        },
        component: () => import("../views/addressbook/country.vue"),
      },
      {
        path: "/warehouse",
        name: "warehouse",
        meta: {
          title: "仓库",
          needLogin:true,
          permission: 'warehouse:view'
        },
        component: () => import("../views/addressbook/warehouse.vue"),
      },
      {
        path: "/iata",
        name: "iata",
        meta: {
          title: "港口",
          needLogin:true,
          permission: 'habour:view'
        },
        component: () => import("../views/basedata/harbour.vue"),
      },
      {
        path: "/rate",
        name: "rate",
        meta: {
          title: "费率",
          needLogin:true,
          permission: 'rate:view'
        },
        component: () => import("../views/basedata/rate.vue"),
      },
      {
        path: "/chargeGroup",
        name: "chargeGroup",
        meta: {
          title: "费用组",
          needLogin:true,
          permission: 'feeGroup:view'
        },
        component: () => import("../views/basedata/chargeGroup.vue"),
      },
      {
        path: "/charge",
        name: "charge",
        meta: {
          title: "费用",
          needLogin:true,
          permission: 'fee:view'
        },
        component: () => import("../views/basedata/charge.vue"),
      },
      {
        path: "/auth",
        name: "auth",
        meta: {
          title: "权限验证",
          needLogin:true,
          permission: 'auth:view'
        },
        component: () => import("../views/auth/index.vue"),
      },
      {
        path: "/broadcast",
        name: "broadcast",
        meta: {
          title: "广播分发",
          needLogin:true,
          permission: 'broadcast:view'
        },
        component: () => import("../views/broadcast/index.vue"),
      },
      {
        path: "/404",
        name: "404",
        meta: {
          title: "找不到页面",
          needLogin:false
        },
        component: () => import("../views/404.vue"),
      },
      {
        path: "/403",
        name: "403",
        meta: {
          title: "没有权限",
          needLogin:false
        },
        component: () => import("../views/403.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      needLogin:false
    },
    component: () => import("../views/Login.vue"),
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


const hasPermission = (code) => {
  const permissions = JSON.parse(localStorage.getItem('permissions'))

  return permissions.find(item => item == code)
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.matched && to.matched.length > 0) {
    document.title = `${to.meta.title} | ToLead-Admin`
    if (to.meta.needLogin) {
      if (localStorage.getItem('access_token')) {
        // 已登录，判断权限
        const role = hasPermission(to.meta.permission)
        if (role) {
          next()
        } else {
          next('/403')
        }
      } else {
        // 未登录，打开登录页面
        next('/login')
      }
    } else {
      next()
    }
  } else {
    next('/404')
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;

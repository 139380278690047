export function validatePassword(rule, value, callback) {
    value = value && value.trim();
    const {
        pwdLength,pwdReg
    } = rule.msg;
    const regChFig = /^[a-zA-Z0-9]+$/

    if (value.length < 6 || value.length >= 20) {
        callback(new Error(pwdLength));
    }
    if (!regChFig.test(value)) {
        callback(new Error(pwdReg));
    } else {
        callback()
    }

}
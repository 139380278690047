import request from "@/api/request";

const mainServer = {
  login: ({ username, password }) =>
    request.post(
      `/oauth/token?grant_type=password&scope=all&username=${username}&password=${password}&client_id=tl-admin&client_secret=123`,
      {}
    ),

    


  logout: (params) =>
    request({
      url: "/oauth/logout",
      method: "get",
      params: params,
    }),

  changePwd: (params) => request.post("/baseUser/changePwd", params),

  
  getSaleCompany: (params) =>
    request({
      url: "/customer/getSaleCompany",
      method: "get",
      params: params,
    }),

};

export default mainServer;
